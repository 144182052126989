import ClosableModal from '@/common/components/ClosableModal';
import NotificationButton from '@/common/components/NotificationButton';
import { useApplicationContext } from '@/context/ApplicationContext';
import ChannelTalkButton from '@/modules/cs/components/ChannelTalkButton';
import NotificationEventCardList from '@/modules/notifications/components/NotificationEventCardList';
import type { INotificationEvent } from '@/modules/notifications/types';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { gql } from '@apollo/client';
import {
  Avatar,
  Box,
  Flex,
  FlexProps,
  Heading,
  IconButton,
  Image,
  Link,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { Suspense, useState } from 'react';
import {
  type NotificationEventsQuery,
  useNotificationEventsLazyQuery,
  useTopbarQuery,
} from './Topbar.generated';

interface TopbarProps extends FlexProps {
  handleImageClick: () => void;
}

gql`
  query Topbar {
    me {
      # 一見不要に見えるが、これがないとApplicationContextで取得しているmeも再度fetchされてしまうので消さない
      id
      newNotificationCount
    }
  }

  query NotificationEvents($page: Int, $limit: Int) {
    notificationEvents(page: $page, limit: $limit) {
      notificationEvents {
        ...NotificationEventCard_NotificationEvent
      }
    }
  }
`;

const Topbar = ({ handleImageClick, ...rest }: TopbarProps) => {
  const { me } = useApplicationContext();
  const { data, refetch } = useTopbarQuery();
  const [notificationEvents, setNotificationEvents] = useState<INotificationEvent[]>([]);
  const { onOpen: onOpenPopover, onClose: onClosePopover, isOpen: isPopoverOpen } = useDisclosure();
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useScreenInfos();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCompletedNotificationEventsQuery = (data: NotificationEventsQuery) => {
    const fetchedNotificationEvents = data.notificationEvents
      .notificationEvents as INotificationEvent[];
    setNotificationEvents(fetchedNotificationEvents);
  };

  const [notificationEventsQuery, { loading: loadingNotificationEventsQuery }] =
    useNotificationEventsLazyQuery({
      variables: {
        page: 1,
        limit: 30,
      },
      onCompleted: onCompletedNotificationEventsQuery,
      fetchPolicy: 'network-only',
    });

  const onClickNotifications = async () => {
    const query = notificationEventsQuery();
    if (isMobile) {
      setIsModalOpen(true);
    } else {
      onOpenPopover();
    }
    await query;
    refetch();
  };

  return (
    <Box position={'sticky'} top={0} zIndex={5}>
      <Flex
        height='40px'
        minW='100vw'
        alignItems='center'
        justifyContent='space-between'
        bg='neutral.200'
        borderBottomWidth='2px'
        borderBottomColor='neutral.300'
        {...rest}
      >
        <Flex>
          <Image
            ml={{ base: 3, md: 5 }}
            cursor='pointer'
            onClick={handleImageClick}
            src='/logo.png'
            // チラつくのでcssで直接指定
            style={{ maxWidth: '100px' }}
            alt='M2X-Logo'
            width='100px'
            height='14px'
          ></Image>
        </Flex>
        <Flex gap={3} mx={2}>
          <Flex gap={2} alignItems={'center'}>
            <Suspense>
              <ChannelTalkButton />
            </Suspense>
            {isMobile && (
              <NotificationButton
                aria-label='list notifications'
                count={data?.me?.newNotificationCount ?? 0}
                onClick={onClickNotifications}
              />
            )}
            {isDesktop && (
              <Popover
                onOpen={onClickNotifications}
                onClose={onClosePopover}
                isOpen={isPopoverOpen}
                placement={'bottom-end'}
              >
                <PopoverTrigger>
                  <NotificationButton
                    aria-label='list notifications'
                    count={data?.me?.newNotificationCount ?? 0}
                  />
                </PopoverTrigger>
                <Portal>
                  <Box
                    sx={{
                      '& .chakra-popover__popper': {
                        zIndex: 'popover',
                      },
                    }}
                  >
                    <PopoverContent
                      width={'90vw'}
                      maxWidth={'500px'}
                      maxHeight={'90vh'}
                      overflowY={'auto'}
                      boxShadow={'1px 2px 5px 1px rgba(0, 0, 0, 0.3)'}
                    >
                      <PopoverArrow />
                      <PopoverHeader>
                        <Heading size={'md'}>{t('notifications.title', '通知')}</Heading>
                      </PopoverHeader>
                      <PopoverBody>
                        <NotificationEventCardList
                          isLoading={loadingNotificationEventsQuery}
                          notificationEvents={notificationEvents}
                          onNavigate={() => onClosePopover()}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Box>
                </Portal>
              </Popover>
            )}
            <Link href='/account'>
              <IconButton
                aria-label={'account'}
                icon={<Avatar size='sm' fontWeight='bold' name={me?.name} />}
                bg='neutral.200'
                fontSize={'1.5em'}
                isRound={true}
                _hover={{ bg: 'neutral.300' }}
              />
            </Link>
          </Flex>
        </Flex>
      </Flex>
      {isMobile && (
        <ClosableModal
          isOpen={isModalOpen}
          size='full'
          scrollBehavior='inside'
          onClose={() => setIsModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton top={1} />
            <ModalHeader minHeight='40px' display='flex' alignItems='center' py={'auto'} px={3}>
              <Heading size={'md'}>{t('notifications.title', '通知')}</Heading>
            </ModalHeader>
            <ModalBody p={1} borderTop='1px' borderColor='neutral.300'>
              <NotificationEventCardList
                isLoading={loadingNotificationEventsQuery}
                notificationEvents={notificationEvents}
                onNavigate={() => setIsModalOpen(false)}
              />
            </ModalBody>
          </ModalContent>
        </ClosableModal>
      )}
    </Box>
  );
};

export default Topbar;
