import useTranslation from '@/utils/i18n/useTranslation';
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { FC } from 'react';
import type { INotificationEvent } from '../types';
import NotificationEventCard from './NotificationEventCard';

type NotificationEventCardListProps = {
  isLoading: boolean;
  notificationEvents: INotificationEvent[];
  onNavigate?: () => void;
};

const NotificationEventCardList: FC<NotificationEventCardListProps> = (
  props: NotificationEventCardListProps
) => {
  const { isLoading, notificationEvents } = props;
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Flex alignItems={'center'} justifyContent={'center'}>
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box>
      {notificationEvents.length === 0 ? (
        <Text>{t('notifications.no-notifications', '通知はありません')}</Text>
      ) : (
        <Box>
          {notificationEvents.map((notificationEvent) => (
            <NotificationEventCard
              key={notificationEvent.id}
              notificationEvent={notificationEvent}
              onNavigate={props.onNavigate}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default NotificationEventCardList;
