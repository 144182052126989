import { useApplicationContext } from '@/context/ApplicationContext';
import { useNotificationContext } from '@/context/NotificationContext';
import { BottomNavigationTypeEnum } from '@/graphql/types';
import { isTruthy } from '@/utils/array/array';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { gql } from '@apollo/client';
import {
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  Icon,
  IconButton,
  Image,
  Link,
  Spacer,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate, useRevalidator } from '@remix-run/react';
import { ReactNode, useMemo } from 'react';
import { IconType } from 'react-icons';
import {
  FaBoxOpen,
  FaChartBar,
  FaCheckCircle,
  FaClipboard,
  FaCog,
  FaRobot,
  FaTasks,
} from 'react-icons/fa';
import { FaRegCalendarDays } from 'react-icons/fa6';
import {
  MdArrowForwardIos,
  MdDoneAll,
  MdEditDocument,
  MdFactory,
  MdGroups,
  MdNoteAlt,
  MdPerson,
  MdQuestionAnswer,
} from 'react-icons/md';
import { useLocalStorage } from 'usehooks-ts';
import BottomNavigation from './BottomNavigation';
import { useSidebarContentQuery } from './Sidebar.generated';
import Topbar from './Topbar';

export type LinkItemType = {
  name: string;
  icon: IconType;
  link: string;
  defaultLabel: string;
  count?: number;
};

const LinkItems: Record<BottomNavigationTypeEnum, LinkItemType> = {
  task: { name: 'task', icon: FaCheckCircle, link: '/', defaultLabel: 'タスク' },
  checkList: {
    name: 'check-list',
    icon: FaTasks,
    link: '/check-lists/',
    defaultLabel: 'チェックリスト',
  },
  schedule: {
    name: 'schedule',
    icon: FaRegCalendarDays,
    link: '/schedules/',
    defaultLabel: 'スケジュール',
  },
  request: { name: 'request', icon: FaClipboard, link: '/requests/', defaultLabel: '依頼' },
  chat: { name: 'chat', icon: MdQuestionAnswer, link: '/chats/', defaultLabel: 'チャット' },
  dashboard: {
    name: 'dashboard',
    icon: FaChartBar,
    link: '/dashboard/',
    defaultLabel: 'ダッシュボード',
  },
  asset: { name: 'asset', icon: MdFactory, link: '/assets/', defaultLabel: '設備' },
  part: {
    name: 'parts',
    icon: FaCog,
    link: '/parts/',
    defaultLabel: '部品',
  },
  user: {
    name: 'user',
    icon: MdPerson,
    link: '/users/',
    defaultLabel: 'ユーザー',
  },
  group: {
    name: 'group',
    icon: MdGroups,
    link: '/groups/',
    defaultLabel: 'グループ',
  },
  product: {
    name: 'product',
    icon: FaBoxOpen,
    link: '/products/',
    defaultLabel: '品目',
  },
  manual: {
    name: 'manual',
    icon: MdNoteAlt,
    link: '/manuals/',
    defaultLabel: 'マニュアル',
  },
  bot: {
    name: 'ai-bot',
    icon: FaRobot,
    link: '/reports/',
    defaultLabel: 'repo',
  },
  report: {
    name: 'report',
    icon: MdEditDocument,
    link: '/reports/',
    defaultLabel: 'reports',
  },
  approval: {
    name: 'approval',
    icon: MdDoneAll,
    link: '/workflows/',
    defaultLabel: 'approval',
  },
};

export default function Sidebar({
  children,
}: {
  children: ReactNode;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isMobile, isDesktop } = useScreenInfos();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();
  const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage<boolean>('is-sidebar-open', true);
  const bottomLinkItems: LinkItemType[] = [];

  const { isAdmin, companySetting } = useApplicationContext();

  const linkItems: LinkItemType[] = useMemo(() => {
    if (!companySetting) return [];
    return [
      LinkItems.task,
      companySetting.accessRequest && LinkItems.request,
      companySetting.accessCheckList && LinkItems.checkList,
      companySetting.accessSchedule && LinkItems.schedule,
      companySetting.accessReport && LinkItems.report,
      companySetting.accessReport && LinkItems.approval,
      LinkItems.chat,
      LinkItems.dashboard,
      LinkItems.asset,
      LinkItems.part,
      companySetting.accessProduct && LinkItems.product,
      companySetting.accessManual && LinkItems.manual,
      companySetting.accessSuggest && LinkItems.bot,

      isAdmin && LinkItems.user,
      isAdmin && companySetting.accessGroup && isDesktop && LinkItems.group,
    ].filter(isTruthy);
  }, [companySetting, isAdmin, isDesktop]);

  const handleImageClick = () => {
    revalidate();
  };

  const handleLinkClick = (link: string) => {
    if (link === '/') {
      window.location.hash = '';
    }
    navigate(link);
    onClose();
  };

  return (
    <Flex direction='column' minH='100vh'>
      <Topbar handleImageClick={handleImageClick} />
      <Flex flex={1} minH={0}>
        {!isMobile && (
          <SidebarContent
            linkItems={linkItems}
            bottomLinkItems={bottomLinkItems}
            handleImageClick={handleImageClick}
            onClose={() => onClose}
            onLinkClick={handleLinkClick}
            isSidebarOpen={isSidebarOpen}
            setOpen={setIsSidebarOpen}
          />
        )}
        <Box bg='neutral.0' flex={1} minW={0}>
          {children}
        </Box>
      </Flex>
      {isMobile && companySetting && (
        <BottomNavigation
          companySetting={companySetting}
          onClickOther={onOpen}
          onLinkClick={handleLinkClick}
          linkItems={LinkItems}
        />
      )}

      {isMobile && (
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement='left'
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size='full'
        >
          <DrawerContent>
            <SidebarContent
              onLinkClick={handleLinkClick}
              linkItems={linkItems}
              bottomLinkItems={bottomLinkItems}
              handleImageClick={handleImageClick}
              onClose={onClose}
            />
          </DrawerContent>
        </Drawer>
      )}
    </Flex>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  handleImageClick: () => void;
  linkItems: LinkItemType[];
  bottomLinkItems: LinkItemType[];
  onLinkClick: (link: string) => void;
  isSidebarOpen?: boolean;
  setOpen?: (value: boolean) => void;
}

export const BottomBarHeight = '64px';

gql`
query SidebarContent {
  countMyPendingWorkflowExecutions
}
`;

const SidebarContent = ({
  onClose,
  handleImageClick,
  linkItems,
  bottomLinkItems,
  onLinkClick,
  isSidebarOpen,
  setOpen,
  ...rest
}: SidebarProps) => {
  const { t } = useTranslation();
  const { newRequestCount, newChatMessageCount } = useNotificationContext();
  const { data } = useSidebarContentQuery({
    pollInterval: 60000,
  });
  const countMap = new Map([
    ['request', newRequestCount],
    ['chat', newChatMessageCount],
    ['approval', data?.countMyPendingWorkflowExecutions],
  ]);

  const handleToggleMenu = (value: boolean) => {
    if (setOpen) {
      setOpen(value);
    }
  };

  return (
    <Flex
      bg='neutral.200'
      borderRight='1px'
      borderRightColor='neutral.300'
      w={{ base: 'full', md: isSidebarOpen === false ? 14 : 52 }}
      overflowX='hidden'
      overflowY='auto'
      top='40px'
      position='sticky'
      transition='width 0.3s ease-in-out'
      h={{ base: 'full', md: 'calc(100vh - 40px)' }}
      {...rest}
      direction='column'
    >
      <Flex h='full' justifyContent='space-between' direction='column'>
        <Box>
          <Flex
            h='20'
            alignItems='center'
            mx='5'
            justifyContent='space-between'
            display={{ base: 'flex', md: 'none' }}
          >
            <Image
              cursor='pointer'
              src='/logo.png'
              alt='M2X'
              // チラつくのでcssで直接指定
              style={{ maxWidth: '100px' }}
              width='100px'
              height='14px'
              onClick={handleImageClick}
            />
            <CloseButton onClick={onClose} />
          </Flex>
          {linkItems.map((link) => (
            <NavItem
              key={link.name}
              icon={link.icon}
              link={link.link}
              label={t(`pages.${link.name}`, link.defaultLabel)}
              count={countMap.get(link.name)}
              onLinkClick={onLinkClick}
              isSidebarOpen={isSidebarOpen}
            />
          ))}
        </Box>
        <Spacer borderBottom='1px solid' borderColor='neutral.300' />
        {bottomLinkItems.map((link) => (
          <NavItem
            key={link.name}
            icon={link.icon}
            link={link.link}
            label={link.name}
            onLinkClick={onLinkClick}
          />
        ))}
        {setOpen && (
          <Flex
            bg='neutral.200'
            borderTop='1px solid'
            borderColor='neutral.300'
            position='sticky'
            bottom='0'
            justifyContent='end'
          >
            <IconButton
              size='lg'
              onClick={() => handleToggleMenu(!isSidebarOpen)}
              icon={<MdArrowForwardIos />}
              bg='transparent'
              aria-label='clear-date'
              rounded='full'
              transform={isSidebarOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
              transition='transform 0.3s ease-in-out'
              _hover={{
                bg: 'neutral.100',
              }}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

interface NavItemProps extends FlexProps {
  link: string;
  icon: IconType;
  label: string;
  count?: number;
  onLinkClick: (link: string) => void;
  isSidebarOpen?: boolean;
}

const NavItem = ({
  link,
  icon,
  label,
  count,
  onLinkClick,
  isSidebarOpen,
  ...rest
}: NavItemProps) => {
  return (
    <Tooltip
      label={label}
      isDisabled={isSidebarOpen}
      placement='right'
      bg='neutral.200'
      color='neutral.900'
      hasArrow
      p={2}
      borderRadius='md'
    >
      <Link
        onClick={() => onLinkClick(link)}
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
        position='relative'
      >
        <Flex
          align='center'
          p='3'
          mx='2'
          borderRadius='lg'
          role='group'
          cursor='pointer'
          _hover={{
            bg: 'primary.500',
            color: 'neutral.0',
          }}
          {...rest}
        >
          {icon && (
            <Box position='relative'>
              <Icon
                mr='4'
                fontSize='16'
                _groupHover={{
                  color: 'neutral.0',
                }}
                as={icon}
              />
              {count !== undefined &&
                count > 0 &&
                isSidebarOpen !== undefined &&
                isSidebarOpen === false && (
                  <Box
                    position='absolute'
                    top='-12px'
                    left='36%'
                    bg='primary.500'
                    borderRadius='50%'
                    color='white'
                    fontSize='0.7em'
                    w='18px'
                    h='18px'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    pointerEvents='none'
                  >
                    {count}
                  </Box>
                )}
            </Box>
          )}
          <Flex justifyContent='space-between' w='100%' overflow='hidden'>
            <p style={{ whiteSpace: 'nowrap' }}>{label}</p>
            {count !== undefined && count > 0 && (
              <Box
                bg='primary.500'
                color='white'
                px={2}
                borderRadius={5}
                fontSize='0.8em'
                display='flex'
                alignItems='center'
                justifyContent='center'
                pointerEvents='none'
              >
                {count}
              </Box>
            )}
          </Flex>
        </Flex>
      </Link>
    </Tooltip>
  );
};
