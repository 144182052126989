import { useNotificationContext } from '@/context/NotificationContext';
import { BottomNavigationTypeEnum } from '@/graphql/types';
import { ICompanySetting, ICompanySettingBottomNavigationItem } from '@/modules/companies';
import { normalizeUrlPath } from '@/utils/atoms/url-params';
import useTranslation from '@/utils/i18n/useTranslation';
import { Box, Flex, FlexProps, Icon, Link, Text } from '@chakra-ui/react';
import { useLocation } from '@remix-run/react';
import { IconType } from 'react-icons';
import { FaBars } from 'react-icons/fa';
import { LinkItemType } from './Sidebar';

export type BottomNavigationProps = {
  companySetting?: ICompanySetting;
  onLinkClick: (link: string) => void;
  onClickOther?: () => void;
  linkItems?: Record<BottomNavigationTypeEnum, LinkItemType>;
};

const BottomNavigation = (props: BottomNavigationProps) => {
  const { companySetting, onClickOther, onLinkClick, linkItems = [] } = props;
  const { t } = useTranslation();
  const { newRequestCount, newChatMessageCount } = useNotificationContext();
  const location = useLocation();
  const normalizedPath = normalizeUrlPath(location.pathname);

  const {
    accessCheckList,
    accessRequest,
    accessSchedule,
    accessManual,
    accessProduct,
    accessGroup,
    accessAiVoice,
    bottomNavigationItems = [],
  } = companySetting || {};

  const menuItems: LinkItemType[] = bottomNavigationItems
    .filter(({ type }) => {
      switch (type) {
        case BottomNavigationTypeEnum.Schedule:
          return accessSchedule;
        case BottomNavigationTypeEnum.CheckList:
          return accessCheckList;
        case BottomNavigationTypeEnum.Request:
          return accessRequest;
        case BottomNavigationTypeEnum.Manual:
          return accessManual;
        case BottomNavigationTypeEnum.Product:
          return accessProduct;
        case BottomNavigationTypeEnum.Group:
          return accessGroup;
        case BottomNavigationTypeEnum.Bot:
          return accessAiVoice;
        default:
          return true;
      }
    })
    .map(({ type }: ICompanySettingBottomNavigationItem) => {
      const menuItem = (linkItems as Record<string, LinkItemType>)[type];
      if (type === BottomNavigationTypeEnum.Request) {
        return { ...menuItem, count: newRequestCount };
      } else if (type === BottomNavigationTypeEnum.Chat) {
        return { ...menuItem, count: newChatMessageCount };
      } else {
        return menuItem;
      }
    });

  return (
    <Flex
      as='nav'
      width='100%'
      bg='white'
      justifyContent='space-evenly'
      alignItems='center'
      position={'sticky'}
      bottom={0}
      paddingTop={2}
      paddingBottom={4}
      borderTop='1px solid #ccc'
      zIndex='10'
    >
      {menuItems.map((item) => (
        <Link
          key={item.name}
          onClick={() => onLinkClick(item.link)}
          style={{ textDecoration: 'none' }}
        >
          <BottomNavigationItem
            icon={item.icon}
            label={t(`pages.${item.name}`, item.defaultLabel)}
            isActive={normalizedPath === item.link}
            count={item.count}
          />
        </Link>
      ))}

      {onClickOther && (
        <BottomNavigationItem
          icon={FaBars}
          isActive={false}
          label={t('pages.menu', 'メニュー')}
          count={newRequestCount + newChatMessageCount}
          onClick={onClickOther}
        />
      )}
    </Flex>
  );
};

interface BottomNavigationItemProps extends FlexProps {
  icon: IconType;
  label: string;
  isActive: boolean;
  count?: number;
  onClick?: () => void;
}

const BottomNavigationItem = ({
  icon,
  label,
  isActive,
  count = 0,
  onClick,
  ...flexProps
}: BottomNavigationItemProps) => {
  return (
    <Flex
      onClick={onClick}
      flexDirection={'column'}
      color={isActive ? 'primary.500' : 'gray.500'}
      justifyContent='center'
      alignItems='center'
      position='relative'
      minWidth='40px'
      {...flexProps}
    >
      <Icon as={icon} boxSize={5} />
      <Text
        fontSize='2xs'
        mt={1}
        whiteSpace='nowrap'
        overflow='hidden'
        textOverflow='ellipsis'
        maxWidth='100%'
      >
        {label}
      </Text>
      {count > 0 && (
        <Box
          position='absolute'
          top='-5px'
          left='calc(50% + 10px)'
          bg='primary.500'
          borderRadius='50%'
          color='white'
          fontSize='0.7em'
          w='20px'
          h='20px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          pointerEvents='none'
        >
          {count}
        </Box>
      )}
    </Flex>
  );
};

export default BottomNavigation;
