import { formatDateToHHmm_or_MD_or_YYYYMD } from '@/utils/date/date';
import { Avatar, Box, Flex, Spacer, Stack, Text } from '@chakra-ui/react';
import { lazy } from 'react';

const WorkOrderCommentCard = lazy(() => import('./WorkOrderCommentCard'));
const ChatMessageCard = lazy(() => import('@/modules/chats/components/ChatMessageCard'));
const RequestCommentCard = lazy(() => import('@/modules/requests/components/RequestCommentCard'));
const PartCardWrapper = lazy(() => import('./PartCardWrapper'));

import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import WorkOrderCard from '@/components/workOrders/WorkOrderCard';
import { formatAssetName } from '@/modules/assets/utils';
import type { PartCard_PartFragment } from '@/modules/parts/components/PartCard.generated';
import ReportCard from '@/modules/reports/components/ReportCard/ReportCard';
import RequestCard from '@/modules/requests/components/RequestCard';
import WorkOrderStatusLabel from '@/modules/workOrders/components/WorkOrderStatusLabel';
import WorkflowCard from '@/modules/workflows/components/WorkflowCard/WorkflowCard';
import { assertNever } from '@/utils/types/types';
import { gql } from '@apollo/client';
import { useNavigate } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import type { INotificationEvent } from '../types';

gql`
fragment NotificationEventCard_NotificationEvent on NotificationEvent {
  id
  description
  eventType
  createdAt
  createdBy {
    id
    name
  }
  request {
    id
    title
    status
    createdAt
    createdBy {
      id
      name
    }
  }
  requestComment {
    id
    comment
  }
  workOrder {
    ...DisplayWorkOrderFragment
  }
  workOrders {
    ...DisplayWorkOrderFragment
  }
  workOrderComment {
    id
    comment
    commentLexical
  }
  chat {
    id
    name
    users {
      user {
        id
        name
      }
    }
  }
  chatMessage {
    id
    message
    messageLexical
  }
  part {
    id
    name
    stock
    unit
    orderPoint
    area
  }
  checkList {
    workOrder {
      ...WorkOrderFragment
    }
  }
  
  commentEvent {
    id
    comment {
      id
      report {
        ...ReportCard_Report
      }
    }
    createdBy {
      id
      name
    }
  }
  
  workflowExecutionEvent {
    id
    __typename
    workflow {
      ...WorkflowCard_WorkflowExecution
    }
    ... on WorkflowSubmittedEvent {
        submittedBy {
            id
            name
        }
    }
    ... on WorkflowRejectedEvent {
        rejectedBy {
          id
          name
        }
    }
    ... on WorkflowApprovedEvent {
        approvedBy {
          id
          name
        }
    }
    ... on WorkflowResubmittedEvent {
        resubmittedBy {
          id
          name
        }
    }
  }
}
`;

type NotificationCardProps = {
  // 本来↑で定義しているfragmentの値をそのまま使えるはずだが、型が合わないので再定義している TODO
  notificationEvent: INotificationEvent;
  onNavigate?: () => void;
};

const systemAdminUserId = 'system-admin';

const NotificationEventCard = (props: NotificationCardProps) => {
  const {
    notificationEvent: {
      eventType,
      createdAt,
      request,
      requestComment,
      workOrder,
      workOrders,
      workOrderComment,
      chat,
      chatMessage,
      part,
      checkList,
      workflowExecutionEvent,
      commentEvent,
    },
    onNavigate = () => {},
  } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleClickWorkOrderCard = (workOrderId: number) => {
    navigate(`/#${workOrderId}`);
    onNavigate();
  };

  const handleClickWorkOrderCommentCard = (workOrderId: number, commentId: number) => {
    navigate(`/?commentId=${commentId}#${workOrderId}`);
    onNavigate();
  };

  const handleClickChatMessageCard = (chatId: number, messageId: number) => {
    navigate(`/chats/?messageId=${messageId}#${chatId}`);
    onNavigate();
  };

  const handleClickPartCard = (partId: number) => {
    navigate(`/parts#${partId}`);
    onNavigate();
  };

  const { description, user } = useSummary(props.notificationEvent);
  return (
    <Flex p={2} w='100%' bgColor='white'>
      <Avatar
        size='sm'
        mr='2'
        fontWeight='bold'
        name={user.name}
        {...(user.id === systemAdminUserId && {
          src: '/push.png',
          showBorder: true,
          borderColor: 'primary.500',
        })}
      />
      <Stack flex='1' w='0'>
        <Flex justifyContent='space-between'>
          <Text color='neutral.800'>{description}</Text>
          <Text color='neutral.500' ml={1}>
            {formatDateToHHmm_or_MD_or_YYYYMD(createdAt)}
          </Text>
        </Flex>
        {eventType === 'request' && request && <RequestCard request={request} />}
        {eventType === 'requestComment' && request && requestComment && (
          <SuspenseWithSpinner>
            <RequestCommentCard request={request} requestComment={requestComment} />
          </SuspenseWithSpinner>
        )}
        {eventType === 'workOrder' && workOrder && (
          <WorkOrderCard
            workOrder={workOrder}
            onWorkOrderClicked={() => {
              handleClickWorkOrderCard(workOrder.id);
            }}
          />
        )}
        {eventType === 'workOrderComment' && workOrder && workOrderComment && (
          <SuspenseWithSpinner>
            <WorkOrderCommentCard
              workOrder={workOrder}
              workOrderComment={workOrderComment}
              onClick={() => {
                handleClickWorkOrderCommentCard(workOrder.id, workOrderComment.id);
              }}
            />
          </SuspenseWithSpinner>
        )}
        {eventType === 'chatMessage' && chat && chatMessage && (
          <SuspenseWithSpinner>
            <ChatMessageCard
              chat={chat}
              chatMessage={chatMessage}
              onClick={() => {
                handleClickChatMessageCard(chat.id, chatMessage.id);
              }}
            />
          </SuspenseWithSpinner>
        )}
        {eventType === 'part' && part && (
          <SuspenseWithSpinner>
            <PartCardWrapper
              part={part as unknown as PartCard_PartFragment}
              onClick={() => {
                handleClickPartCard(part.id);
              }}
            />
          </SuspenseWithSpinner>
        )}
        {eventType === 'workOrderDueDate' &&
          workOrders &&
          workOrders.map((workOrder) => (
            <Box
              p={2}
              _hover={{
                bg: 'neutral.50',
                cursor: 'pointer',
              }}
              borderWidth={1}
              onClick={() => handleClickWorkOrderCard(workOrder.id)}
              key={workOrder.id}
            >
              <Stack align='stretch' textAlign='left'>
                <Flex alignItems='center'>
                  <Text
                    color='neutral.800'
                    {...(workOrder.title && {
                      fontSize: 'lg',
                      letterSpacing: 'wide',
                    })}
                  >
                    {workOrder.title || formatAssetName(workOrder.asset) || t('pages.task')}
                  </Text>
                  <Spacer />
                  <WorkOrderStatusLabel status={workOrder.status} />
                </Flex>
              </Stack>
            </Box>
          ))}

        {eventType === 'checkListAlertTriggered' && checkList?.workOrder && (
          <WorkOrderCard
            workOrder={checkList.workOrder}
            onWorkOrderClicked={() => {
              handleClickWorkOrderCard(checkList.workOrder!.id);
            }}
          />
        )}
        {workflowExecutionEvent?.workflow && (
          <Box p={2} borderWidth={1} onClick={onNavigate}>
            <WorkflowCard execution={workflowExecutionEvent.workflow} />
          </Box>
        )}
        {commentEvent?.comment?.report && (
          <Box p={2} borderWidth={1} onClick={onNavigate}>
            <ReportCard report={commentEvent.comment.report} />
          </Box>
        )}
      </Stack>
    </Flex>
  );
};

function useSummary(notificationEvent: INotificationEvent): {
  description: string;
  user: {
    id: string;
    name: string;
  };
} {
  const { t } = useTranslation('notifications');
  // notification eventsをinterfaceにしてtypeごとに必須フィールドを指定するようにすると不要になるはず TODO
  const fallbackUser = {
    id: notificationEvent.createdBy?.id || '',
    name: notificationEvent.createdBy?.name || '',
  };
  switch (notificationEvent.eventType) {
    case 'requestApproved':
    case 'requestAssigned':
    case 'requestAssignedToGroup':
    case 'requestAssignedToGroupMember':
    case 'requestCancelled':
    case 'requestRejected':
    case 'chatMessage':
    case 'checkListAlertTriggered':
    case 'part':
    case 'request':
    case 'requestComment':
    case 'workOrder':
    case 'workOrderComment':
    case 'workOrderDueDate':
      return {
        description: notificationEvent.description,
        user: fallbackUser,
      };
    case 'commentCreated':
      return {
        description: t('comment.commentCreated', {
          user: notificationEvent.commentEvent?.createdBy?.name,
          title: notificationEvent.commentEvent?.comment?.report?.latestVersion?.title ?? '',
        }),
        user: notificationEvent.createdBy ?? fallbackUser,
      };
    case 'workflowApprovalRequested':
    case 'workflowApproved':
    case 'workflowRejected':
      return {
        description: t(`workflow.${notificationEvent.eventType}`, {
          title: notificationEvent.workflowExecutionEvent?.workflow?.report?.latestVersion?.title,
        }),
        user: (() => {
          const event = notificationEvent.workflowExecutionEvent;
          switch (event?.__typename) {
            case 'WorkflowApprovedEvent':
              return event.approvedBy;
            case 'WorkflowRejectedEvent':
              return event.rejectedBy;
            case 'WorkflowSubmittedEvent':
              return event.submittedBy;
            case 'WorkflowResubmittedEvent':
              return event.resubmittedBy;
            default:
              return fallbackUser;
          }
        })(),
      };
    default:
      assertNever(notificationEvent.eventType);
  }
}

export default NotificationEventCard;
