import * as Types from '../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SidebarContentQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SidebarContentQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'countMyPendingWorkflowExecutions'>
);


export const SidebarContentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SidebarContent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"countMyPendingWorkflowExecutions"}}]}}]} as unknown as DocumentNode;

/**
 * __useSidebarContentQuery__
 *
 * To run a query within a React component, call `useSidebarContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebarContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useSidebarContentQuery(baseOptions?: Apollo.QueryHookOptions<SidebarContentQuery, SidebarContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SidebarContentQuery, SidebarContentQueryVariables>(SidebarContentDocument, options);
      }
export function useSidebarContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SidebarContentQuery, SidebarContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SidebarContentQuery, SidebarContentQueryVariables>(SidebarContentDocument, options);
        }
export function useSidebarContentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SidebarContentQuery, SidebarContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SidebarContentQuery, SidebarContentQueryVariables>(SidebarContentDocument, options);
        }
export type SidebarContentQueryHookResult = ReturnType<typeof useSidebarContentQuery>;
export type SidebarContentLazyQueryHookResult = ReturnType<typeof useSidebarContentLazyQuery>;
export type SidebarContentSuspenseQueryHookResult = ReturnType<typeof useSidebarContentSuspenseQuery>;
export type SidebarContentQueryResult = Apollo.QueryResult<SidebarContentQuery, SidebarContentQueryVariables>;